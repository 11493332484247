import { StyleSheet } from "react-native";

export default StyleSheet.create({
  Continer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#E3E3E3",
  },
  Con: {
    width: "100%",
    maxWidth: 765,
    height: "70%",
    backgroundColor: "#E3E3E3",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  ViewInput: {
    height: 56,
    marginTop: 10,
    borderRadius: 10,
    backgroundColor: "#2E3249",
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },

  textinput: {
    width: "100%",
    height: 56,
    marginLeft: 20,
    fontFamily: "Prompt-Regular",
    color: "#fff",
    fontSize: 18,
  },
  texthreader: {
    fontFamily: "Prompt-Regular",
    fontSize: 16,
    color: "#2E3249",
    marginTop: 40,
  },

  ViewInput1: {
    height: 56,
    marginTop: 30,
    borderRadius: 10,
    backgroundColor: "#2E3249",
    borderWidth: 1,
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  ImgButtom: {
    position: "absolute",
    zIndex: -99,
    marginTop: -2,
    width: "100%",
    height: 56,
    borderRadius: 10,
  },
});
