import React, { Component, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { useRecoilState } from "recoil";
import { apiservice } from "../../apiservice/api";
import { tokenState } from "../../reducer/Atom";
import Style from "./Style";

const { width, height } = Dimensions.get("screen");

export default function login({ navigation }) {
  const [token, settoken] = useRecoilState(tokenState);

  const [username, setusername] = useState("");
  const [Password, setPassword] = useState("");
  const [error, seterror] = useState("examplePWD!123");

  async function LoginAdmin() {
    const response = await apiservice({
      method: "Post",
      path: "api/admin/login",
      body: { email: username, password: Password },
    });

    if (response.status == 200) {
      settoken(response.data);
      navigation.navigate("Home");
    } else {
      seterror(response.data.details);
    }
  }

  return (
    <View style={Style.Continer}>
      <View style={Style.Con}>
        <View
          style={{
            width: "60%",
          }}
        >
          <Image
            resizeMode={"contain"}
            style={{ width: 250, height: 250, alignSelf: "center" }}
            source={require("../../img/logo.png")}
          />
          <Text style={Style.texthreader}>Your Email</Text>
          <View style={Style.ViewInput}>
            <Image
              resizeMode={"stretch"}
              style={{ width: 22, height: 22, marginLeft: 12 }}
              source={require("../../img/email.png")}
            />
            <View />
            <TextInput
              value={username}
              autoCapitalize="none"
              onChangeText={(text) => {
                setusername(text);
              }}
              style={Style.textinput}
            />
          </View>

          <Text style={Style.texthreader}>Your Password</Text>
          <View style={Style.ViewInput}>
            <Image
              resizeMode={"stretch"}
              style={{ width: 22, height: 22, marginLeft: 12 }}
              source={require("../../img/Password.png")}
            />
            <View />
            <TextInput
              secureTextEntry
              value={Password}
              autoCapitalize="none"
              onChangeText={(text) => {
                setPassword(text);
              }}
              style={Style.textinput}
            />
          </View>
          {error == "Email is required" && (
            <Text style={[Style.texthreader, { color: "red" }]}>
              ไม่มี Email อยู๋ในระบบ
            </Text>
          )}
          {error == "Password is required" && (
            <Text style={[Style.texthreader, { color: "red" }]}>
              รหัสผ่านผิด
            </Text>
          )}
          {error == "Invalid email" && (
            <Text style={[Style.texthreader, { color: "red" }]}>
              ไม่มี Email อยู๋ในระบบ
            </Text>
          )}
          <TouchableOpacity
            onPress={() => {
              LoginAdmin();
            }}
            style={Style.ViewInput1}
          >
            <Image
              style={Style.ImgButtom}
              source={require("../../img/Button.png")}
            />

            <Text style={{ color: "#fff", fontSize: 36 }}>Log in</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
