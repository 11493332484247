import { StyleSheet } from "react-native";

export default StyleSheet.create({
  viewDetail: {
    width: "95%",
    marginRight: 25,
    alignSelf: "flex-end",
    backgroundColor: "#FFFFFF",
    marginTop: -50,
    borderRadius: 4,
    padding: 40,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  textSuject: {
    fontSize: 30,
    fontFamily: "Roboto",
    color: "#595959",
    fontWeight: "bold",
  },
  textDetail1: {
    color: "#acacac",
    fontWeight: "normal",
  },
  viewAdd: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 40,
  },
  buttonAddDescription: {
    width: 114,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
  },
  imgAddDescription: {
    width: 114,
    height: 40,
    position: "absolute",
    borderRadius: 5,
  },
  textSubjectModal: {
    fontSize: 36,
    fontFamily: "Roboto",
    color: "#161A31",
    fontWeight: "bold",
  },
  textSublject: {
    fontSize: 20,
    fontFamily: "Roboto",
    color: "#FFFFFF",
    textAlign: "center",
  },
  viewSubject: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#161A31",
    borderRadius: 5,
    height: 59,
    paddingHorizontal: 45,
    alignItems: "center",
    marginTop: 33,
  },
  textDetail: {
    fontSize: 20,
    fontFamily: "Roboto",
    color: "#535659",
    textAlign: "center",
  },
  viewDetailAccess: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#e8e9eb",
    paddingVertical: 22,
    paddingHorizontal: 45,
    marginTop: 17,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewModal: {
    width: "40%",
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    padding: 35,
    alignSelf: "center",
  },
  textTitleModal: {
    fontSize: 36,
    fontFamily: "Roboto",
    color: "#161A31",
    alignSelf: "center",
    fontWeight: "bold",
  },
  buttonModal: {
    width: 87,
    height: 33,
    backgroundColor: "#E8E9EB",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  viewDeleteModal: {
    width: "30%",
    height: 44,
    backgroundColor: "#161A31",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  inputModal: {
    width: "100%",
    height: 31,
    borderWidth: 1,
    borderColor: "#dcdde0",
    borderRadius: 5,
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#787878",
    paddingHorizontal: 10,
  },
  textSubjectModal1: {
    fontSize: 18,
    fontFamily: "Roboto",
    color: "#161A31",
    fontWeight: "bold",
  },
  viewDetailModal: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 10,
  },
  buttonUploadModal: {
    width: "15%",
    height: 31,
    backgroundColor: "#161A31",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
  },
  buttonUploadImgModal: {
    width: 43,
    height: 31,
    backgroundColor: "#161A31",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    alignSelf: "flex-end",
  },
  TextButtonBack: {
    fontSize: 36,
    fontFamily: "Roboto",
    color: "#595959",
    marginLeft: 20,
    fontWeight: "bold",
  },
  buttoEdit: {
    width: 114,
    height: 40,
    backgroundColor: "#161A31",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
});
