import React from "react";
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native";
import { useWindowSize } from "./responsive";
export default function titleButton({ title, select, onPress }) {
  const size = useWindowSize();
  return (
    <TouchableOpacity onPress={onPress} style={styles.button}>
      {select && (
        <Image style={styles.bgButton} source={require("../img/0006.png")} />
      )}
      <View style={styles.viewTitle}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Image
            style={{ width: 17, height: 17 }}
            source={
              select ? require("../img/dot.png") : require("../img/dot1.png")
            }
          />
          <Text
            style={[
              styles.textTitle,
              {
                color: select ? "#FFFFFF" : "#595959",
                fontSize: (size.width * 1.2) / 100,
              },
            ]}
          >
            {title}
          </Text>
        </View>
        <Image
          style={{ width: 10, height: 19 }}
          source={
            select ? require("../img/0004.png") : require("../img/0005.png")
          }
        />
      </View>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  button: {
    width: "100%",
    height: 91,
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
  },
  bgButton: {
    width: "100%",
    height: 91,
    position: "absolute",
  },
  viewTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 30,
    alignItems: "center",
  },
  textTitle: {
    fontSize: 24,
    fontFamily: "Roboto",
    marginLeft: 20,
  },
});
