import axios from "axios";
export let BaseUrl = "https://api.connectvar.com/";
// let BaseUrl = "http://54.169.196.73:5000/";
async function apiservice({ method, path, body, token }) {
  try {
    let header = {
      "Content-Type": "application/json",
    };

    if (token != undefined) {
      header.Authorization = "Bearer " + token;
    }
    const response = await axios({
      method: method,
      data: body,
      url: BaseUrl + path,
      headers: header,
    });
    if (response.status == 200) {
      return {
        data: response.data,
        status: response.status,
      };
    }

    return response;
  } catch (error) {
    return { data: error.response.data, status: error.response.status };
  }
}

export { apiservice };
