import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  FlatList,
  ScrollView,
  ActivityIndicator,
  Platform,
} from "react-native";
import fs from "fs";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Spinner from "react-native-loading-spinner-overlay";
import Menu from "../../component/menu";
import ReactPlayer from "react-player";
import HeaderTitle from "../../component/headerTitle";
import { useWindowSize } from "../../component/responsive";
import Modal from "modal-react-native-web";
import Description from "../../component/descriptionModal";
import RNPickerSelect from "react-native-picker-select";
import styles from "./Style";
import * as DocumentPicker from "expo-document-picker";
import { tokenState } from "../../reducer/Atom";
import { useRecoilState } from "recoil";
import { apiservice, BaseUrl } from "../../apiservice/api";
import axios from "axios";
import moment from "moment";
import child from "child_process";
import { useIsFocused } from "@react-navigation/native";
import AWS from "aws-sdk";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
const S3_BUCKET = "project-var-whishes";
const REGION = "ap-southeast-1";

AWS.config.update({
  accessKeyId: "AKIA5QXMEPVM3CXAEDG5",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default function Detail({ navigation, route }) {
  const [datamedia, setMedia] = useState(JSON.parse(route.params.data));
  const [loading, setLoading] = useState(false);
  const [modalAddDescription, setModalAddDescription] = useState(false);
  const focus = useIsFocused();
  const [modalEditMedia, setModalEditMedia] = useState(false);
  const [token, settoken] = useRecoilState(tokenState);
  const hiddenFileInputDetector = React.useRef(null);
  const hiddenFileInputResult = React.useRef(null);
  const hiddenFileInpuEditMedia = React.useRef(null);
  const hiddenFileInputAddon = React.useRef(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [Delete, setDelete] = useState({});
  const [error, seterror] = useState(false);
  const newFileName = moment().format("DD-MM-YYYY-HH-mm-ss");

  const ref = useRef();
  const [itema, setitem] = useState([
    { label: "3D", value: "3D" },
    { label: "IMAGE", value: "IMAGE" },
    { label: "PANORAMA", value: "PANORAMA" },
    { label: "VIDEO", value: "VIDEO" },
    { label: "WEB", value: "WEB" },
  ]);

  const [body1, setbody1] = useState({
    upload_cover_image: "",
    media_name: "",
    media_description: "",
    media_type: "",
    is_public: true,
    expired_at: new Date(),
    media_lat: "",
    media_lng: "",
    is_required_location: false,
    location_radius: 0.5,
    location_radius_unit: "KM",
  });

  const [body, setbody] = useState({
    content_page: 1,
    content_type: "",
    content_description: "",
    upload_detector_image: null,
    content_video: null,
    upload_result_file: "",
    upload_additional_files: [],
    content_desc: null,
  });

  const anotherFunc = (val) => {
    setbody({
      content_page: "1",
      content_type: "",
      content_description: "",
      content_video: null,
      upload_detector_image: "",
      upload_result_file: "",
      upload_additional_files: [],
      content_desc: "",
    });
    setshowDetector("");
    setshowResults("");
  };

  const [showDetector, setshowDetector] = useState(null);
  const [showResults, setshowResults] = useState(null);

  const handleClickDetector = (event) => {
    hiddenFileInputDetector.current.click();
  };

  const handleChangeDetector = async (event) => {
    const fileUploaded = event.target.files[0];
    const url = URL.createObjectURL(fileUploaded);

    setshowDetector(url);
    setbody((val) => ({ ...val, upload_detector_image: fileUploaded }));
  };

  const handleClickAddon = (event) => {
    hiddenFileInputAddon.current.click();
  };

  const handleChangeAddon = async (event) => {
    const fileUploaded = event.target.files[0];
    const url = URL.createObjectURL(fileUploaded);

    setbody((val) => ({
      ...val,
      upload_additional_files: val.upload_additional_files.concat({
        fileUploaded,
        base64: url,
      }),
    }));
  };

  const handleClickResult = (event) => {
    hiddenFileInputResult.current.click();
  };

  const handleChangeResult = (event) => {
    const fileUploaded = event.target.files[0];
    const url = URL.createObjectURL(fileUploaded);

    const dataArray = new FormData();
    dataArray.append("upload_cover_image", fileUploaded);

    axios
      .post(BaseUrl + "api/admin_media/element", dataArray, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token.access_token,
        },
      })
      .then((response) => {
        if (fileUploaded.name.includes(".mp4")) {
          setshowResults(require("../../img/film.png"));
        } else {
          setshowResults({ uri: url });
        }
        setbody((val) => ({
          ...val,
          upload_result_file: response?.data,
          content_video: response?.data,
        }));
      })
      .catch((error) => {
        // error response
      });

    // const params = {
    //   Bucket: "project-var-whishes",
    //   Key:
    //     "media/" +
    //     newFileName +
    //     "." +
    //     fileUploaded.name.split(".").reverse()[0], // File name you want to save as in S3
    //   Body: fileUploaded,
    // };

    // s3.upload(params, function (err, data) {
    //   if (err) {
    //     console.log(err);
    //     return;
    //   }

    //   console.log(`File uploaded successfully. ${data.Location}`);
    // });

    // const params = {
    //   Body: fileUploaded,
    //   Bucket: S3_BUCKET,
    //   Key: "media/" + fileUploaded.name,
    // };

    // myBucket
    //   .putObject(params)
    //   .on("httpUploadProgress", (evt) => {
    //     console.log(Math.round((evt.loaded / evt.total) * 100));
    //   })
    //   .send((err) => {
    //     if (err) console.log(err);
    //   });
  };

  const handleClickEditMedia = (event) => {
    hiddenFileInpuEditMedia.current.click();
  };

  const handleChangeEditMedia = (event) => {
    const fileUploaded = event.target.files[0];
    const url = URL.createObjectURL(fileUploaded);
    console.log(fileUploaded);
    if (fileUploaded.name.includes(".mp4")) {
      setshowResults(require("../../img/film.png"));
    } else {
      setshowResults({ uri: url });
    }

    setbody1((val) => ({ ...val, upload_cover_image: fileUploaded }));
  };

  async function mediatypesall() {
    const response = await apiservice({
      method: "get",
      path: "api/admin_media_types",
      token: token.access_token,
    });

    if (response.status == 200) {
      setSelectType(
        response.data.result.media.map((item) => ({
          label: item.type_name,
          value: item.type_slug,
        }))
      );
    }
  }

  async function mediatypeall() {
    const response = await apiservice({
      method: "get",
      path: "api/admin_media/media/" + JSON.parse(route.params.data).media_uid,
      token: token.access_token,
    });

    if (response.status == 200) {
      setMedia(response.data.result.media);
    }
  }

  useEffect(() => {
    setMedia(JSON.parse(route.params.data));
    mediatypesall();
  }, [focus]);

  async function addContent() {
    const dataArray = new FormData();
    dataArray.append("content_page", body.content_page);
    dataArray.append("content_type", body.content_type);
    dataArray.append("content_desc", body.content_desc);
    dataArray.append("content_video", body.content_video);
    dataArray.append("content_description", body.content_description);
    dataArray.append("upload_detector_image", body.upload_detector_image);
    dataArray.append(
      "upload_result_file",
      body.content_type == "WEB" || body.content_type == "VIDEO"
        ? body.upload_detector_image
        : body.upload_result_file
    );
    body?.upload_additional_files?.map((item) =>
      dataArray.append("upload_additional_files", item.fileUploaded)
    );
    setLoading(true);
    axios
      .post(
        BaseUrl + "api/admin_media/media/" + datamedia.media_uid + "/content",
        dataArray,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token.access_token,
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          setTimeout(() => {
            setshowDetector(null);
            setshowResults(null);
            anotherFunc(
              body.content_page,
              body.content_description,
              body.content_desc,
              body.upload_detector_image,
              body.upload_result_file,
              showDetector,
              showResults
            );
            setitem([]);
            anotherFunc(body.content_type);
            setLoading(false);
            setModalAddDescription(!modalAddDescription);
            mediatypeall();
          }, 2000);
        }

        // successfully uploaded response
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function editContent() {
    const dataArray = new FormData();
    dataArray.append("content_page", body.content_page);
    dataArray.append("content_type", body.content_type);
    dataArray.append("content_video", body.content_video);
    dataArray.append("content_description", body.content_description);
    dataArray.append("content_desc", body.content_desc);

    dataArray.append(
      "upload_detector_image",
      body.content_detector_url || body.upload_detector_image
    );
    dataArray.append(
      "upload_result_file",
      body.content_type == "WEB" || body.content_type == "VIDEO"
        ? body.upload_detector_image || body.content_detector_url
        : body.upload_result_file || body.content_result_url
    );
    body?.upload_additional_files?.map((item) =>
      dataArray.append("upload_additional_files", item.fileUploaded)
    );
    setLoading(true);
    axios
      .put(
        BaseUrl +
          "api/admin_media/media/" +
          datamedia.media_uid +
          "/content/" +
          body.content_uid,
        dataArray,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token.access_token,
          },
        }
      )

      .then((response) => {
        setTimeout(() => {
          setModalAddDescription(!modalAddDescription);
          setLoading(false);
          setitem([]);
          mediatypeall();
        }, 2000);
        anotherFunc(
          body.content_page,
          body.content_description,
          body.content_desc,
          body.upload_detector_image,
          body.upload_result_file,
          showDetector,
          showResults
        );
        anotherFunc(body.content_type);
      })
      .catch((error) => {
        // error response
      });
  }

  const size = useWindowSize();
  const placeholder = {
    label: "Type",
    value: "",
    color: "#cccccc",
  };
  const [selectType, setSelectType] = useState([]);

  useEffect(() => {
    mediatypeall();
  }, [token]);

  const callApi = () => {
    const dataArray = new FormData();
    dataArray.append(
      "upload_cover_image",
      body1.upload_cover_image
        ? body1.upload_cover_image
        : body1.media_cover_url
    );
    dataArray.append("media_name", body1.media_name);
    dataArray.append("media_description", body1.media_description);
    dataArray.append("media_type", body1.media_type);
    dataArray.append("is_public", body1.is_public);
    dataArray.append("expired_at", body1.expired_at);
    dataArray.append(
      "media_lat",
      body1.is_required_location ? parseFloat(body1.media_lat) : "null"
    );
    dataArray.append(
      "media_lng",
      body1.is_required_location ? parseFloat(body1.media_lng) : "null"
    );
    dataArray.append("is_required_location", body1.is_required_location);

    axios
      .put(
        BaseUrl + "api/admin_media/media/" + datamedia.media_uid,
        dataArray,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token.access_token,
          },
        }
      )

      .then((response) => {
        setModalEditMedia(!modalEditMedia);
        mediatypeall();
        setbody1({});
      })
      .catch((error) => {
        seterror(true);
        console.log("error", error);
      });
  };

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <ScrollView>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Menu book navigation={navigation} />
          <View style={{ width: "80%" }}>
            <HeaderTitle title="Media information" navigation={navigation} />
            <View style={[styles.viewDetail, { minHeight: "80%" }]}>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("Book");
                }}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <Image
                  style={{ width: 20, height: 35 }}
                  source={require("../../img/back.png")}
                />
                <Text style={styles.TextButtonBack}>Back</Text>
              </TouchableOpacity>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  marginTop: 20,
                }}
              >
                <Image
                  style={{ width: "15%", height: 248, borderRadius: 5 }}
                  source={{ uri: datamedia.media_cover_url }}
                />
                <View
                  style={{
                    width: "80%",
                    paddingHorizontal: 30,
                  }}
                >
                  <Text style={styles.textSuject}>
                    Type:{" "}
                    <Text style={styles.textDetail1}>
                      {datamedia.media_type}
                    </Text>
                  </Text>
                  <Text style={styles.textSuject}>
                    Title:{" "}
                    <Text style={styles.textDetail1}>
                      {datamedia.media_name}
                    </Text>
                  </Text>
                  <Text style={styles.textSuject}>
                    Description:
                    <Text style={styles.textDetail1}>
                      {datamedia.media_description}
                    </Text>
                  </Text>

                  <Text style={styles.textSuject}>
                    created-Add:
                    <Text style={styles.textDetail1}>
                      {moment(datamedia.updated_at).format("L")}
                    </Text>
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setbody1(datamedia);
                      setModalEditMedia(true);
                    }}
                    style={styles.buttoEdit}
                  >
                    <Text
                      style={{
                        fontSize: 24,
                        fontFamily: "Roboto",
                        color: "#FFFFFF",
                      }}
                    >
                      Edit
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.viewAdd}>
                <Text style={styles.textSubjectModal}>Description</Text>

                {datamedia?.is_required_location == true &&
                  datamedia.Contents.length == 0 && (
                    <TouchableOpacity
                      onPress={() => {
                        setitem([
                          { label: "3D", value: "3D" },
                          { label: "IMAGE", value: "IMAGE" },
                          { label: "PANORAMIC", value: "PANORAMIC" },
                          { label: "VIDEO", value: "VIDEO" },
                          { label: "WEB", value: "WEB" },
                        ]);
                        setModalAddDescription(true);
                        anotherFunc(
                          body.content_page,
                          body.content_description,
                          body.content_desc,
                          body.upload_detector_image,
                          body.upload_result_file,
                          showDetector,
                          showResults
                        ),
                          anotherFunc(body.content_type);
                      }}
                      style={styles.buttonAddDescription}
                    >
                      <Image
                        style={styles.imgAddDescription}
                        source={require("../../img/upload.png")}
                      />
                      <Text
                        style={[
                          styles.textSubjectModal,
                          {
                            color: "#FFFFFF",
                            fontSize: (size.width * 1) / 100,
                            zIndex: 99,
                          },
                        ]}
                      >
                        Add
                      </Text>
                    </TouchableOpacity>
                  )}

                {datamedia?.is_required_location == false && (
                  <TouchableOpacity
                    onPress={() => {
                      setitem([
                        { label: "3D", value: "3D" },
                        { label: "IMAGE", value: "IMAGE" },
                        { label: "PANORAMIC", value: "PANORAMIC" },
                        { label: "VIDEO", value: "VIDEO" },
                        { label: "WEB", value: "WEB" },
                      ]);
                      setModalAddDescription(true);
                      anotherFunc(
                        body.content_page,
                        body.content_description,
                        body.content_desc,
                        body.upload_detector_image,
                        body.upload_result_file,
                        showDetector,
                        showResults
                      ),
                        anotherFunc(body.content_type);
                    }}
                    style={styles.buttonAddDescription}
                  >
                    <Image
                      style={styles.imgAddDescription}
                      source={require("../../img/upload.png")}
                    />
                    <Text
                      style={[
                        styles.textSubjectModal,
                        {
                          color: "#FFFFFF",
                          fontSize: (size.width * 1) / 100,
                          zIndex: 99,
                        },
                      ]}
                    >
                      Add
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
              <View style={styles.viewSubject}>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "5%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  No.
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Page
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "10%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  Type
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "25%",

                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  Preview Detector
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "25%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Preview Result
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Edit
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Delete
                </Text>
              </View>
              <FlatList
                data={datamedia.Contents.sort(
                  (a, b) => b.content_id - a.content_id
                )}
                numColumns={1}
                renderItem={({ index, item }) => {
                  // console.log("sort", item);
                  return (
                    <View style={styles.viewDetailAccess}>
                      <Text
                        style={[
                          styles.textDetail,
                          { width: "5%", textAlign: "left" },
                        ]}
                      >
                        {index + 1}
                      </Text>
                      <Text style={[styles.textDetail, { width: "10%" }]}>
                        {item.content_page}
                      </Text>
                      <Text
                        style={[
                          styles.textDetail,
                          { width: "10%", textAlign: "left" },
                        ]}
                      >
                        {item.content_type}
                      </Text>
                      <View style={{ width: "25%" }}>
                        <Image
                          style={{
                            width: "80%",
                            height: 168,
                            alignSelf: "center",
                          }}
                          source={{ uri: item.content_detector_url }}
                        />
                      </View>
                      <View style={{ width: "25%" }}>
                        {item.content_type == "IMAGE" && (
                          <Image
                            style={{
                              width: "80%",
                              height: 168,
                              alignSelf: "center",
                            }}
                            source={{ uri: item.content_result_url }}
                          />
                        )}
                        {item.content_type == "VIDEO" && (
                          <ReactPlayer
                            width={"80%"}
                            height={168}
                            url={item.content_result_url}
                          />
                        )}
                        {item.content_type != "IMAGE" &&
                          item.content_type != "VIDEO" && (
                            <View
                              style={{
                                width: "80%",
                                height: 168,
                                alignSelf: "center",
                                backgroundColor: "#555",
                                borderRadius: 10,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Text
                                style={[
                                  styles.textDetail,
                                  { width: "100%", color: "#fff" },
                                ]}
                              >
                                {
                                  item?.content_result_url
                                    ?.split(".")
                                    ?.reverse()[0]
                                }
                              </Text>
                            </View>
                          )}
                      </View>

                      <View style={{ width: "10%" }}>
                        <TouchableOpacity
                          onPress={() => {
                            setitem([
                              { label: "3D", value: "3D" },
                              { label: "IMAGE", value: "IMAGE" },
                              { label: "PANORAMIC", value: "PANORAMIC" },
                              { label: "VIDEO", value: "VIDEO" },
                              { label: "WEB", value: "WEB" },
                            ]);
                            setbody({
                              ...item,
                              content_desc: item.content_description,
                              content_video: item.content_result_url,
                            });
                            setshowDetector(item.content_detector_url);
                            setshowResults(item.content_result_url);
                            setModalAddDescription(true);
                          }}
                          style={{ alignSelf: "center" }}
                        >
                          <Image
                            style={{ width: 30, height: 30 }}
                            source={require("../../img/edit.png")}
                          />
                        </TouchableOpacity>
                      </View>
                      <View style={{ width: "10%" }}>
                        <TouchableOpacity
                          onPress={() => {
                            setModalDelete(true);
                            setDelete(item);
                          }}
                          style={{ alignSelf: "center" }}
                        >
                          <Image
                            style={{ width: 26, height: 30 }}
                            source={require("../../img/deleteIC.png")}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  );
                }}
              />
            </View>
          </View>
        </View>
        <Modal
          transparent={true}
          visible={modalAddDescription}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setbody({});
            setModalAddDescription(!modalAddDescription);
          }}
        >
          <View style={styles.bgModal}>
            <View style={styles.viewModal}>
              {loading == true && (
                <View
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    backgroundColor: "#000000bb",
                    justifyContent: "center",
                    zIndex: 99,
                    marginLeft: -35,
                    marginTop: -35,
                  }}
                >
                  <ActivityIndicator size="large" color="#FFF" />
                </View>
              )}
              <Text style={styles.textTitleModal}>Add Description</Text>
              <View style={{ width: "49%" }}>
                <Text style={styles.textSubjectModal}>Page</Text>
                <TextInput
                  onChangeText={(text) => {
                    setbody({ ...body, content_page: text });
                  }}
                  value={body.content_page}
                  style={styles.inputModal}
                />
              </View>
              <View>
                <View style={[styles.viewDetailModal, { marginTop: 20 }]}>
                  <View style={{ width: "49%" }}>
                    <Text style={styles.textSubjectModal}>Description</Text>
                    <TextInput
                      onChangeText={(text) => {
                        setbody({ ...body, content_desc: text });
                      }}
                      value={body.content_desc}
                      style={styles.inputModal}
                    />
                  </View>

                  <View style={{ width: "49%" }}>
                    <Text style={styles.textSubjectModal}>Media Type</Text>
                    <RNPickerSelect
                      placeholder={placeholder}
                      onValueChange={(val) => {
                        setbody({ ...body, content_type: val });
                      }}
                      items={itema}
                      value={body.content_type}
                      style={{
                        inputWeb: {
                          backgroundColor: "#FFFFFF",
                          borderColor: "#dcdde0",
                          borderRadius: 5,
                          width: "100%",
                          height: 31,
                          color: "#8B8D98",
                          fontFamily: "Prompt",
                          fontSize: 16,
                          paddingHorizontal: 10,
                        },
                      }}
                    />
                  </View>
                </View>
                <Text style={[styles.textSubjectModal, { marginTop: 10 }]}>
                  Filles
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",

                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      width: "49%",
                    }}
                  >
                    <View style={{ width: "83%", marginRight: 5 }}>
                      <Text style={[styles.textSubjectModal, { fontSize: 14 }]}>
                        Detector
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={[styles.inputModal, { paddingTop: 5 }]}
                      >
                        {showDetector}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "15%",
                        alignSelf: "flex-end",
                      }}
                    >
                      <TouchableOpacity
                        onPress={handleClickDetector}
                        style={styles.buttonUploadImgModal}
                      >
                        <input
                          type="file"
                          ref={hiddenFileInputDetector}
                          onChange={handleChangeDetector}
                          style={{ display: "none" }}
                        />
                        <Image
                          style={{
                            width: 28,
                            height: 19,
                            borderRadius: 5,
                          }}
                          source={require("../../img/uploadicon.png")}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "49%",
                    }}
                  >
                    <View style={{ width: "83%", marginRight: 5 }}>
                      <Text style={[styles.textSubjectModal, { fontSize: 14 }]}>
                        Result
                      </Text>
                      {body.content_type != "WEB" && (
                        <Text
                          numberOfLines={1}
                          style={[styles.inputModal, { paddingTop: 5 }]}
                        >
                          {showResults?.uri}
                        </Text>
                      )}
                      {body.content_type == "WEB" && (
                        <TextInput
                          onChangeText={(val) => {
                            setbody({ ...body, content_description: val });
                          }}
                          style={[styles.inputModal, { paddingTop: 5 }]}
                          value={body.content_description}
                        />
                      )}
                    </View>
                    {body.content_type != "WEB" && (
                      <View
                        style={{
                          width: "15%",
                          alignSelf: "flex-end",
                        }}
                      >
                        <TouchableOpacity
                          onPress={handleClickResult}
                          style={styles.buttonUploadImgModal}
                        >
                          <input
                            type="file"
                            ref={hiddenFileInputResult}
                            onChange={handleChangeResult}
                            style={{ display: "none" }}
                          />
                          <Image
                            style={{
                              width: 28,
                              height: 19,
                              borderRadius: 5,
                            }}
                            source={require("../../img/uploadicon.png")}
                          />
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 18,
                  }}
                >
                  <View style={{ width: "49%", alignItems: "center" }}>
                    {showDetector && (
                      <Image
                        style={{ width: "100%", height: 157, borderRadius: 5 }}
                        source={{ uri: showDetector }}
                      />
                    )}
                    <Text
                      style={[
                        styles.textSubjectModal,
                        { fontSize: 14, marginTop: 10 },
                      ]}
                    >
                      Preview Detector
                    </Text>
                  </View>
                  <View style={{ width: "49%" }}>
                    {showResults && (
                      <Image
                        style={{
                          width: "100%",
                          height: 157,
                          borderRadius: 5,
                          backgroundColor: "#555",
                        }}
                        source={showResults}
                      />
                    )}

                    <Text
                      style={[
                        styles.textSubjectModal,
                        { fontSize: 14, marginTop: 10, textAlign: "center" },
                      ]}
                    >
                      Preview Result
                    </Text>
                    <Text
                      style={[
                        styles.textSubjectModal,
                        { fontSize: 14, marginTop: 10, textAlign: "right" },
                      ]}
                    >
                      ไฟล์ขนาดไม่เกิน 100MB.
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    width: "49%",
                    marginTop: 25,
                    display:
                      body.content_type == "3D" || body.content_type == "IMAGE"
                        ? "flex"
                        : "none",
                  }}
                >
                  <View style={{ width: "35%", marginRight: 5 }}>
                    <Text
                      numberOfLines={1}
                      style={[
                        styles.inputModal,
                        { paddingTop: 5, borderWidth: 0 },
                      ]}
                    >
                      Additional
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "15%",
                      alignSelf: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      onPress={handleClickAddon}
                      style={styles.buttonUploadImgModal}
                    >
                      <input
                        type="file"
                        ref={hiddenFileInputAddon}
                        onChange={handleChangeAddon}
                        style={{ display: "none" }}
                      />
                      <Image
                        style={{
                          width: 28,
                          height: 19,
                          borderRadius: 5,
                        }}
                        source={require("../../img/uploadicon.png")}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                    {body?.upload_additional_files?.map((item) => {
                      return (
                        <View style={{ marginTop: 15 }}>
                          <View
                            style={{
                              width: 150,
                              alignItems: "center",
                              marginRight: 15,
                            }}
                          >
                            <Image
                              style={{
                                width: "100%",
                                height: 157,
                                borderRadius: 5,
                              }}
                              source={item.base64}
                            />
                            <Text
                              style={[
                                styles.textSubjectModal,
                                { fontSize: 14, marginTop: 10 },
                              ]}
                            >
                              Preview Result
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                    {body?.content_additional_files?.map((item) => {
                      return (
                        <View style={{ marginTop: 15 }}>
                          <View
                            style={{
                              width: 150,
                              alignItems: "center",
                              marginRight: 15,
                            }}
                          >
                            <Image
                              style={{
                                width: "100%",
                                height: 157,
                                borderRadius: 5,
                              }}
                              source={{ uri: item.url }}
                            />
                            <Text
                              style={[
                                styles.textSubjectModal,
                                { fontSize: 14, marginTop: 10 },
                              ]}
                            >
                              Preview Result
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </ScrollView>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignSelf: "flex-end",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    anotherFunc(
                      body.content_page,
                      body.content_description,
                      body.upload_detector_image,
                      body.upload_result_file,
                      showDetector,
                      showResults
                    ),
                      anotherFunc(body.content_type);
                    setitem([]);
                    setModalAddDescription(!modalAddDescription);
                  }}
                  style={styles.buttonModal}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#808080", fontSize: 16 },
                    ]}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    console.log(body.content_uid ? "NO" : "YES");
                    body.content_uid ? editContent() : addContent();
                  }}
                  style={[
                    styles.buttonModal,
                    { backgroundColor: "#161A31", marginLeft: 10 },
                  ]}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#FFFFFF", fontSize: 16 },
                    ]}
                  >
                    Save
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalDelete}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalDelete(!modalDelete);
          }}
        >
          <View style={styles.bgModal}>
            <View style={styles.viewDeleteModal}>
              <Text
                style={{ fontSize: 24, fontFamily: "Roboto", color: "#FFFFFF" }}
              >
                Delete
              </Text>
            </View>
            <View
              style={[
                styles.viewModal,
                {
                  width: "30%",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={{
                  fontSize: (size.width * 1.2) / 100,
                  fontFamily: "Roboto",
                  color: "#404040",
                }}
              >
                Do you want to delete data?
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignSelf: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setModalDelete(!modalDelete);
                  }}
                  style={styles.buttonModal}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#808080", fontSize: 16 },
                    ]}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={async () => {
                    // console.log(
                    //   "api/admin_media/media/" +
                    //     Delete.media_uid +
                    //     "/content/" +
                    //     Delete.content_uid
                    // );
                    // console.log(Delete);
                    const response = await apiservice({
                      method: "Delete",
                      path:
                        "api/admin_media/media/" +
                        Delete.media_uid +
                        "/content/" +
                        Delete.content_uid,
                      token: token.access_token,
                    });
                    if (response.status == 200) {
                      console.log("Delete", response.data);
                      setModalDelete(!modalDelete);
                      mediatypeall();
                    }
                  }}
                  style={[
                    styles.buttonModal,
                    { backgroundColor: "#161A31", marginLeft: 10 },
                  ]}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#FFFFFF", fontSize: 16 },
                    ]}
                  >
                    Ok
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalEditMedia}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalEditMedia(!modalEditMedia);
          }}
        >
          <View style={styles.bgModal}>
            <View style={styles.viewModal}>
              <Text style={styles.textTitleModal}>Edit Media</Text>
              <View style={styles.viewDetailModal}>
                <View style={{ width: "68%" }}>
                  <Text style={styles.textSubjectModal1}>Title</Text>
                  <TextInput
                    value={body1?.media_name}
                    onChangeText={(text) => {
                      setbody1((val) => ({ ...val, media_name: text }));
                    }}
                    placeholder="Title"
                    style={styles.inputModal}
                  />
                </View>
                <View style={{ width: "30%" }}>
                  <Text style={styles.textSubjectModal1}>Media Type</Text>
                  <RNPickerSelect
                    placeholder={placeholder}
                    onValueChange={(value) => {
                      setbody1((val) => ({ ...val, media_type: value }));
                    }}
                    value={body1?.media_type}
                    items={selectType}
                    style={{
                      inputWeb: {
                        backgroundColor: "#FFFFFF",
                        borderColor: "#dcdde0",
                        borderRadius: 5,
                        width: "100%",
                        height: 31,
                        color: "#8B8D98",
                        fontFamily: "Prompt",
                        fontSize: 16,
                        paddingHorizontal: 10,
                      },
                    }}
                  />
                </View>
              </View>
              <Text style={[styles.textSubjectModal1, { marginTop: 10 }]}>
                Description
              </Text>
              <TextInput
                value={body1?.media_description}
                onChangeText={(text) => {
                  setbody1((val) => ({ ...val, media_description: text }));
                }}
                multiline
                placeholder="Description"
                style={[styles.inputModal, { minHeight: 100, padding: 10 }]}
              />
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 14,
                }}
              >
                <View style={{ width: "83%" }}>
                  <Text style={styles.textSubjectModal1}>Cover</Text>
                  <Text style={[styles.inputModal, { paddingTop: 5 }]}>
                    book_cover.jpg
                  </Text>
                </View>

                <TouchableOpacity
                  onPress={handleClickEditMedia}
                  style={styles.buttonUploadModal}
                >
                  <input
                    type="file"
                    ref={hiddenFileInpuEditMedia}
                    onChange={handleChangeEditMedia}
                    style={{ display: "none" }}
                  />
                  <Text
                    style={[
                      styles.textSubjectModal1,
                      { color: "#FFFFFF", fontSize: (size.width * 1) / 100 },
                    ]}
                  >
                    Upload
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  onPress={() => {
                    if (body1.is_public == false) {
                      setbody1((val) => ({ ...val, is_public: true }));
                    } else {
                      setbody1((val) => ({ ...val, is_public: false }));
                    }
                  }}
                  style={{ padding: 10 }}
                >
                  <MaterialCommunityIcons
                    name="checkbox-blank"
                    size={24}
                    color={body1?.is_public ? "black" : "#ccc"}
                  />
                </TouchableOpacity>

                <Text style={[styles.textSubjectModal1, { marginTop: 7 }]}>
                  public
                </Text>
              </View>
              {body1?.is_public && (
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() => {
                      console.log("location", body1.is_required_location);
                      if (body1?.is_required_location == false) {
                        setbody1((val) => ({
                          ...val,
                          is_required_location: true,
                        }));
                      } else {
                        setbody1((val) => ({
                          ...val,
                          is_required_location: false,
                          media_lat: null,
                          media_lng: null,
                        }));
                      }
                    }}
                    style={{ padding: 10 }}
                  >
                    <MaterialCommunityIcons
                      name="checkbox-blank"
                      size={24}
                      color={body1?.is_required_location ? "black" : "#ccc"}
                    />
                  </TouchableOpacity>

                  <Text style={[styles.textSubjectModal1, { marginTop: 7 }]}>
                    Location
                  </Text>
                </View>
              )}
              {body1?.is_required_location && (
                <Text style={styles.textSubjectModal1}>Latitude</Text>
              )}

              {body1?.is_required_location && (
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    value={body1.media_lat}
                    onChangeText={(text) => {
                      setbody1((val) => ({ ...val, media_lat: text }));
                    }}
                    placeholder="Title"
                    style={styles.inputModal}
                  />
                </View>
              )}
              {error == true && (
                <Text
                  style={{
                    color: "red",
                    fontSize: 16,
                    padding: 5,
                    fontFamily: "Roboto",
                  }}
                >
                  กรุณาใส่ Latitude
                </Text>
              )}

              {body1?.is_required_location && (
                <Text style={styles.textSubjectModal1}>Longitude</Text>
              )}
              {body1?.is_required_location && (
                <TextInput
                  value={body1.media_lng}
                  onChangeText={(text) => {
                    setbody1((val) => ({ ...val, media_lng: text }));
                  }}
                  placeholder="Title"
                  style={styles.inputModal}
                />
              )}
              {error == true && (
                <Text
                  style={{
                    color: "red",
                    fontSize: 16,
                    padding: 5,
                    fontFamily: "Roboto",
                  }}
                >
                  กรุณาใส่ Longitude
                </Text>
              )}
              {<Text style={styles.textSubjectModal1}>expired date</Text>}
              {/* {
              <TextInput
                value={body1.expired_at}
                onChangeText={(text) => {
                  setbody1((val) => ({ ...val, expired_at: text }));
                }}
                placeholder="Title"
                style={styles.inputModal}
              />
            } */}
              <TouchableOpacity
                onPress={() => {
                  setModalVisible((val) => !val);
                }}
              >
                <Text style={styles.textSubjectModal1}>
                  {moment(body1?.expired_at)
                    .add(-1, "days")
                    .format("DD/MM/YYYY")}
                </Text>
              </TouchableOpacity>
              {modalVisible == true && (
                <Calendar
                  onChange={(value) => {
                    setModalVisible(false);

                    // setbody1((val) => ({ ...val, expired_at: value }));
                    setbody1((val) => ({
                      ...val,
                      expired_at: moment(value)
                        .add(+1, "days")
                        .format("YYYY-MM-DD"),
                    }));
                  }}
                  // value={body1.expired_at}
                />
              )}
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignSelf: "flex-end",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setbody1(null);
                    setModalEditMedia(!modalEditMedia);
                    seterror(false);
                  }}
                  style={styles.buttonModal}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#808080", fontSize: 16 },
                    ]}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    seterror(false);

                    callApi();
                  }}
                  style={[
                    styles.buttonModal,
                    { backgroundColor: "#161A31", marginLeft: 10 },
                  ]}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#FFFFFF", fontSize: 16 },
                    ]}
                  >
                    Save
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </View>
  );
}
