import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  FlatList,
  ScrollView,
} from "react-native";
import Menu from "../../component/menu";
import HeaderTitle from "../../component/headerTitle";
import RNPickerSelect from "react-native-picker-select";
import { useWindowSize } from "../../component/responsive";
import Modal from "modal-react-native-web";
import styles from "./Styles";
import Description from "../../component/descriptionModal";
import { tokenState } from "../../reducer/Atom";
import { useRecoilState } from "recoil";
import { apiservice, BaseUrl } from "../../apiservice/api";
import axios from "axios";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import moment from "moment";

import { useIsFocused } from "@react-navigation/native";

export default function Book({ navigation, route }) {
  const [modalAddMedia, setModalAddMedia] = useState(false);
  const [token, settoken] = useRecoilState(tokenState);
  const [calenNum, setcalenNum] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const hiddenFileInputDetector = React.useRef(null);
  const hiddenFileInputResult = React.useRef(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [demoImage, setdemoImage] = useState(null);
  const [value, onChange] = useState(new Date());
  const [modalVisible, setModalVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [media_type, smedia_type] = useState(null);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [Delete, setDelete] = useState({});
  const [state, setstate] = useState([]);
  const [modalerror, setModalEderrof] = useState(false);
  const placeholder = {
    label: "All",
    value: null,
    color: "#cccccc",
  };

  const [day, setday] = useState(moment(Date.now()).format("YYYY-MM-DD"));

  const focus = useIsFocused();
  const anotherFunc = (val) => {
    setdemoImage(null);
    setbody({
      upload_cover_image: "",
      media_name: "",
      media_description: "",
      media_type: null,
      is_public: true,
      expired_at: new Date(),
      media_lat: null,
      media_lng: null,
      is_required_location: false,
      location_radius: 20,
      location_radius_unit: "KM",
    });
  };

  const [body, setbody] = useState({
    upload_cover_image: "",
    media_name: "",
    media_description: "",
    media_type: "",
    is_public: true,
    expired_at: moment(new Date())
      .add(+1, "days")
      .format("YYYY-MM-DD"),
    media_lat: null,
    media_lng: null,
    is_required_location: false,
    location_radius: 20,
    location_radius_unit: "KM",
  });
  console.log("day", body.expired_at);
  const download = (e) => {
    fetch(e, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [selectType, setSelectType] = useState([
    { label: "book", value: "book" },
    { label: "card", value: "card" },
  ]);
  const size = useWindowSize();
  const [itema, setitem] = useState("");

  async function mediatypeall() {
    const response = await apiservice({
      method: "get",
      path: "api/admin_media_types",
      token: token.access_token,
    });

    if (response.status == 200) {
      setitem(
        response.data.result.media.map((item) => ({
          label: item.type_name,
          value: item.type_slug,
        }))
      );
      setSelectType(
        response.data.result.media.map((item) => ({
          label: item.type_name,
          value: item.type_slug,
        }))
      );
    }
  }

  async function mediaall() {
    const response = await apiservice({
      method: "get",
      path: "api/admin_media/media?page=1&limit=100&search=",
      token: token.access_token,
    });

    if (response.status == 200) {
      setstate(response.data.result.media);
    }
  }

  useEffect(() => {
    mediaall();
    mediatypeall();
  }, [token, focus]);

  const handleClickDetector = (event) => {
    hiddenFileInputDetector.current.click();
  };

  const handleChangeDetector = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
  };

  const handleClickCover = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChangeCover = (event) => {
    const fileUploaded = event.target.files[0];
    const url = URL.createObjectURL(fileUploaded);
    setdemoImage(url);
    setbody((val) => ({ ...val, upload_cover_image: fileUploaded }));
  };

  const handleClickResult = (event) => {
    hiddenFileInputResult.current.click();
  };

  const handleChangeResult = (event) => {
    const fileUploaded = event.target.files[0];
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
  };

  const callApi = () => {
    const dataArray = new FormData();
    dataArray.append("upload_cover_image", body.upload_cover_image);
    dataArray.append("media_name", body.media_name);
    dataArray.append("media_description", body.media_description);
    dataArray.append("media_type", body.media_type);
    dataArray.append("is_public", body.is_public);
    dataArray.append("expired_at", body.expired_at);
    dataArray.append("media_lat", body.media_lat);
    dataArray.append("media_lng", body.media_lng);
    dataArray.append("is_required_location", body.is_required_location);
    dataArray.append("location_radius", body.location_radius);
    dataArray.append("location_radius_unit", body.location_radius_unit);

    axios
      .post(BaseUrl + "api/admin_media/media", dataArray, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token.access_token,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          anotherFunc(body);
          mediaall();
          mediatypeall();
          setModalAddMedia(!modalAddMedia);
        } else {
          setModalEderrof(!modalerror);
        }
      })
      .catch((error) => {
        setModalAddMedia(!modalAddMedia);
        setTimeout(() => {
          setModalEderrof(!modalerror);
        }, 500);

        // error response
      });
  };

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <ScrollView>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Menu book navigation={navigation} />
          <View style={{ width: "80%" }}>
            <HeaderTitle title="Media information" navigation={navigation} />
            <View style={[styles.viewDetail, { minHeight: "80%" }]}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ flexDirection: "row", width: "50%" }}>
                  <RNPickerSelect
                    placeholder={{ label: "All", value: null }}
                    value={media_type}
                    onValueChange={(val) => {
                      smedia_type(val);
                    }}
                    items={selectType}
                    style={{
                      inputWeb: {
                        backgroundColor: "#FFFFFF",
                        borderColor: "#8B8D98",
                        width: 165,
                        height: 58,
                        color: "#8B8D98",
                        fontFamily: "Prompt",
                        fontSize: 16,
                        paddingHorizontal: 10,
                        borderRadius: 5,
                      },
                    }}
                  />
                  <View style={{ width: "100%", marginLeft: 15 }}>
                    <Image
                      style={styles.imgGlass}
                      source={require("../../img/glass-icon.png")}
                    />
                    <TextInput
                      defaultValue={search}
                      onChangeText={(text) => {
                        setSearch(text);
                      }}
                      placeholder="ค้นหา"
                      style={styles.inputSeach}
                    />
                  </View>
                </View>
                <View
                  style={{
                    width: "20%",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setModalAddMedia(true);
                    }}
                    style={styles.buttonAdd}
                  >
                    <Image
                      style={styles.imgAdd}
                      source={require("../../img/addMedia.png")}
                    />
                    <Text
                      style={{
                        fontSize: (size.width * 2) / 100,
                        fontFamily: "Roboto",
                        color: "#FFFFFF",
                        zIndex: 99,
                      }}
                    >
                      Add Media
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.viewSubject}>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "5%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  No.
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Type
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "25%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  Media Name
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "25%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  Description
                </Text>
                {/* <Text
                style={[
                  styles.textSublject,
                  { width: "10%", fontSize: (size.width * 1) / 100 },
                ]}
              >
                status
              </Text> */}
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  QRcode
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Detail
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "5%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Delete
                </Text>
              </View>
              <FlatList
                data={state
                  .sort((a, b) => {
                    return b.media_id - a.media_id;
                  })
                  .filter((items) => {
                    return items.delete_flag == false;
                  })
                  .filter((item) => {
                    return item.media_name
                      .toLowerCase()
                      .includes(search.toLowerCase());
                  })
                  .filter((item) => {
                    if (media_type) {
                      return item.media_type == media_type;
                    } else {
                      return true;
                    }
                  })}
                numColumns={1}
                renderItem={({ index, item }) => {
                  console.log("sort", item.media_type);
                  {
                    return (
                      <View style={styles.viewDetailAccess}>
                        <Text
                          style={[
                            styles.textDetail,
                            {
                              width: "5%",
                              textAlign: "left",
                              fontSize: (size.width * 1) / 100,
                            },
                          ]}
                        >
                          {index + 1}
                        </Text>
                        <Text
                          style={[
                            styles.textDetail,
                            { width: "10%", fontSize: (size.width * 1) / 100 },
                          ]}
                        >
                          {item.media_type}
                        </Text>
                        <Text
                          style={[
                            styles.textDetail,
                            {
                              width: "25%",
                              textAlign: "left",
                              fontSize: (size.width * 1) / 100,
                            },
                          ]}
                        >
                          {item.media_name}
                        </Text>
                        <Text
                          style={[
                            styles.textDetail,
                            {
                              width: "25%",
                              textAlign: "left",
                              fontSize: (size.width * 1) / 100,
                            },
                          ]}
                        >
                          {item.media_description}
                        </Text>
                        {/* <Text
                      style={[
                        styles.textDetail,
                        { width: "10%", fontSize: (size.width * 1) / 100 },
                      ]}
                    >
                      {item.status}
                    </Text> */}
                        <View style={{ width: "10%" }}>
                          <TouchableOpacity
                            onPress={() => {
                              window.open(item.media_qr_url);
                              // download(item.media_qr_url);
                            }}
                            style={{ alignSelf: "center" }}
                          >
                            <Image
                              style={{
                                width: (size.width * 1.5) / 100,
                                height: (size.width * 1.5) / 100,
                              }}
                              source={require("../../img/qrCode.png")}
                            />
                          </TouchableOpacity>
                        </View>
                        <View style={{ width: "10%" }}>
                          <TouchableOpacity
                            onPress={async () => {
                              const response = await apiservice({
                                method: "get",
                                path: "api/admin_media/media/" + item.media_uid,
                                token: token.access_token,
                              });

                              if (response.status == 200) {
                                navigation.navigate("Detail", {
                                  data: JSON.stringify(
                                    response.data.result.media
                                  ),
                                });
                              }
                            }}
                            style={{ alignSelf: "center" }}
                          >
                            <Image
                              style={{
                                width: (size.width * 1.5) / 100,
                                height: (size.width * 1.5) / 100,
                              }}
                              source={require("../../img/detail.png")}
                            />
                          </TouchableOpacity>
                        </View>
                        <View style={{ width: "5%" }}>
                          <TouchableOpacity
                            onPress={() => {
                              setModalDelete(!modalDelete);
                              setDelete(item);
                            }}
                            style={{ alignSelf: "center" }}
                          >
                            <Image
                              style={{
                                width: (size.width * 1.5) / 100,
                                height: (size.width * 1.6) / 100,
                              }}
                              source={require("../../img/deleteIC.png")}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    );
                  }
                }}
              />
            </View>
          </View>
        </View>

        <Modal
          transparent={true}
          visible={modalerror}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalEderrof(!modalerror);
          }}
        >
          <View style={styles.bgModal}>
            <View style={styles.viewDeleteModal}>
              <Text
                style={{ fontSize: 24, fontFamily: "Roboto", color: "#FFFFFF" }}
              >
                Error
              </Text>
            </View>
            <View
              style={[
                styles.viewModal,
                {
                  width: "30%",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={{
                  fontSize: (size.width * 1.2) / 100,
                  fontFamily: "Roboto",
                  color: "#404040",
                }}
              >
                There is a duplicate title in the system.
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignSelf: "center",
                }}
              >
                <TouchableOpacity
                  onPress={async () => {
                    setModalEderrof(!modalerror);
                  }}
                  style={[
                    styles.buttonModal,
                    { backgroundColor: "#161A31", marginLeft: 10 },
                  ]}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#FFFFFF", fontSize: 16 },
                    ]}
                  >
                    Ok
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>

        <Modal
          transparent={true}
          visible={modalAddMedia}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalAddMedia(!modalAddMedia);
          }}
        >
          <View style={styles.bgModal}>
            <View style={styles.viewModal}>
              <Text style={styles.textTitleModal}>Add Media</Text>
              <View style={styles.viewDetailModal}>
                <View style={{ width: "68%" }}>
                  <Text style={styles.textSubjectModal}>Title</Text>
                  <TextInput
                    value={body.media_name}
                    onChangeText={(text) => {
                      setbody((val) => ({ ...val, media_name: text }));
                    }}
                    placeholder="Title"
                    style={styles.inputModal}
                  />
                </View>
                <View style={{ width: "30%" }}>
                  <Text style={styles.textSubjectModal}>Media Type</Text>
                  <RNPickerSelect
                    placeholder={placeholder}
                    onValueChange={(val) => {
                      setbody({ ...body, media_type: val });
                    }}
                    value={body.media_type}
                    items={itema}
                    style={{
                      inputWeb: {
                        backgroundColor: "#FFFFFF",
                        borderColor: "#dcdde0",
                        borderRadius: 5,
                        width: "100%",
                        height: 31,
                        color: "#8B8D98",
                        fontFamily: "Prompt",
                        fontSize: 16,
                        paddingHorizontal: 10,
                      },
                    }}
                  />
                </View>
              </View>
              <Text style={[styles.textSubjectModal, { marginTop: 10 }]}>
                Description
              </Text>
              <TextInput
                onChangeText={(text) => {
                  setbody((val) => ({ ...val, media_description: text }));
                }}
                value={body.media_description}
                multiline
                placeholder="Description"
                style={[styles.inputModal, { minHeight: 100, padding: 10 }]}
              />
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 14,
                }}
              >
                <View style={{ width: "83%" }}>
                  <Text style={styles.textSubjectModal}>Cover</Text>
                  <Text style={[styles.inputModal, { paddingTop: 5 }]}>
                    {demoImage}
                  </Text>
                </View>

                <TouchableOpacity
                  onPress={handleClickCover}
                  style={styles.buttonUploadModal}
                >
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChangeCover}
                    style={{ display: "none" }}
                  />
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#FFFFFF", fontSize: (size.width * 1) / 100 },
                    ]}
                  >
                    Upload
                  </Text>
                </TouchableOpacity>
              </View>
              {demoImage && (
                <Image
                  style={{ width: 220, height: 157, marginVertical: 15 }}
                  source={{ uri: demoImage }}
                />
              )}
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  onPress={() => {
                    if (body.is_public == false) {
                      setbody((val) => ({ ...val, is_public: true }));
                    } else {
                      setbody((val) => ({ ...val, is_public: false }));
                    }
                  }}
                  style={{ padding: 10 }}
                >
                  <MaterialCommunityIcons
                    name="checkbox-blank"
                    size={24}
                    color={body.is_public ? "black" : "#ccc"}
                  />
                </TouchableOpacity>

                <Text style={[styles.textSubjectModal, { marginTop: 7 }]}>
                  public
                </Text>
              </View>
              {body.is_public && (
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() => {
                      if (body.is_required_location == false) {
                        setbody((val) => ({
                          ...val,
                          is_required_location: true,
                        }));
                      } else {
                        setbody((val) => ({
                          ...val,
                          is_required_location: false,
                        }));
                      }
                    }}
                    style={{ padding: 10 }}
                  >
                    <MaterialCommunityIcons
                      name="checkbox-blank"
                      size={24}
                      color={body.is_required_location ? "black" : "#ccc"}
                    />
                  </TouchableOpacity>

                  <Text style={[styles.textSubjectModal, { marginTop: 7 }]}>
                    Location
                  </Text>
                </View>
              )}
              {body.is_required_location && (
                <Text style={styles.textSubjectModal}>Latitude</Text>
              )}

              {body.is_required_location && (
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    value={body.media_lat}
                    onChangeText={(text) => {
                      setbody((val) => ({ ...val, media_lat: text }));
                    }}
                    placeholder="Title"
                    style={styles.inputModal}
                  />
                </View>
              )}

              {body.is_required_location && (
                <Text style={styles.textSubjectModal}>Longitude</Text>
              )}
              {body.is_required_location && (
                <TextInput
                  value={body.media_lng}
                  onChangeText={(text) => {
                    setbody((val) => ({ ...val, media_lng: text }));
                  }}
                  placeholder="Title"
                  style={styles.inputModal}
                />
              )}
              {<Text style={styles.textSubjectModal}>expired date</Text>}

              <TouchableOpacity
                onPress={() => {
                  setModalVisible1(false);
                  setModalVisible((val) => !val);
                }}
              >
                <Text style={styles.textSubjectModal1}>
                  {moment(day).add(543, "years").format("DD/MM/YYYY")}
                </Text>
              </TouchableOpacity>
              {modalVisible == true && (
                <Calendar
                  onChange={(value) => {
                    setModalVisible(false);

                    setbody((val) => ({
                      ...val,
                      expired_at: moment(value)
                        .add(+1, "days")
                        .format("YYYY-MM-DD"),
                    }));
                    setday(moment(value));
                  }}
                  value={value}
                />
              )}

              {/* {
              <TextInput
                value={body.expired_at}
                onChangeText={(text) => {
                  setbody((val) => ({ ...val, expired_at: text }));
                }}
                placeholder="Title"
                style={styles.inputModal}
              />
            } */}
              <FlatList
                data={body.add}
                extraData={[calenNum, body]}
                renderItem={({ item, index }) => {
                  return (
                    <View>
                      <View style={[styles.viewDetailModal, { marginTop: 20 }]}>
                        <View style={{ width: "49%" }}>
                          <Text style={styles.textSubjectModal}>Page</Text>
                          <TextInput
                            value={item.title}
                            onChangeText={(text) => {
                              setbody((val) => ({
                                ...val,
                                add: val.add.map((items, indexs) => {
                                  if (index == indexs) {
                                    return {
                                      ...items,
                                      page: text,
                                    };
                                  } else {
                                    return items;
                                  }
                                }),
                              }));
                            }}
                            style={styles.inputModal}
                          />
                        </View>
                        <View style={{ width: "49%" }}>
                          <Text style={styles.textSubjectModal}>
                            Media Type
                          </Text>
                          <RNPickerSelect
                            placeholder={placeholder}
                            onValueChange={(value) => {
                              setbody((val) => ({
                                ...val,
                                add: val.add.map((items, indexs) => {
                                  if (index == indexs) {
                                    return { ...items, type: value };
                                  } else {
                                    return items;
                                  }
                                }),
                              }));
                            }}
                            items={itema}
                            style={{
                              inputWeb: {
                                backgroundColor: "#FFFFFF",
                                borderColor: "#dcdde0",
                                borderRadius: 5,
                                width: "100%",
                                height: 31,
                                color: "#8B8D98",
                                fontFamily: "Prompt",
                                fontSize: 16,
                                paddingHorizontal: 10,
                              },
                            }}
                          />
                        </View>
                      </View>
                      <Text
                        style={[styles.textSubjectModal, { marginTop: 10 }]}
                      >
                        Filles
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",

                          width: "100%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            width: "49%",
                          }}
                        >
                          <View style={{ width: "83%", marginRight: 5 }}>
                            <Text
                              style={[
                                styles.textSubjectModal,
                                { fontSize: 14 },
                              ]}
                            >
                              Detector
                            </Text>
                            <Text
                              numberOfLines={1}
                              style={[styles.inputModal, { paddingTop: 5 }]}
                            >
                              {}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "15%",
                              alignSelf: "flex-end",
                            }}
                          >
                            <TouchableOpacity
                              onPress={handleClickDetector}
                              style={styles.buttonUploadImgModal}
                            >
                              <input
                                type="file"
                                ref={hiddenFileInputDetector}
                                onChange={handleChangeDetector}
                                style={{ display: "none" }}
                              />
                              <Image
                                style={{
                                  width: 28,
                                  height: 19,
                                  borderRadius: 5,
                                }}
                                source={require("../../img/uploadicon.png")}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "49%",
                          }}
                        >
                          <View style={{ width: "83%", marginRight: 5 }}>
                            <Text
                              style={[
                                styles.textSubjectModal,
                                { fontSize: 14 },
                              ]}
                            >
                              Result
                            </Text>
                            <Text
                              numberOfLines={1}
                              style={[styles.inputModal, { paddingTop: 5 }]}
                            >
                              Page 15 image.jpg
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "15%",
                              alignSelf: "flex-end",
                            }}
                          >
                            <TouchableOpacity
                              onPress={handleClickResult}
                              style={styles.buttonUploadImgModal}
                            >
                              <input
                                type="file"
                                ref={hiddenFileInputResult}
                                onChange={handleChangeResult}
                                style={{ display: "none" }}
                              />
                              <Image
                                style={{
                                  width: 28,
                                  height: 19,
                                  borderRadius: 5,
                                }}
                                source={require("../../img/uploadicon.png")}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 18,
                        }}
                      >
                        <View style={{ width: "49%", alignItems: "center" }}>
                          <Image
                            source={{
                              uri:
                                item.Detector != ""
                                  ? item.Detector
                                  : require("../../img/img.png"),
                            }}
                            style={{
                              width: "100%",
                              height: 157,
                              borderRadius: 5,
                            }}
                          />

                          <Text
                            style={[
                              styles.textSubjectModal,
                              { fontSize: 14, marginTop: 10 },
                            ]}
                          >
                            Preview Detector
                          </Text>
                        </View>
                        <View style={{ width: "49%", alignItems: "center" }}>
                          <Image
                            style={{
                              width: "100%",
                              height: 157,
                              borderRadius: 5,
                            }}
                            source={{
                              uri:
                                item.Result != ""
                                  ? item.Result
                                  : require("../../img/img.png"),
                            }}
                          />
                          <Text
                            style={[
                              styles.textSubjectModal,
                              { fontSize: 14, marginTop: 10 },
                            ]}
                          >
                            Preview Result
                          </Text>
                        </View>
                      </View>
                    </View>
                  );
                }}
              />

              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignSelf: "flex-end",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    anotherFunc(
                      body.upload_cover_image,
                      body.media_name,
                      body.media_description,
                      body.media_type,
                      body.is_public,
                      body.expired_at,
                      body.media_lat,
                      body.media_lng,
                      body.is_required_location,
                      body.location_radius,
                      body.location_radius_unit
                    );
                    setModalAddMedia(!modalAddMedia);
                  }}
                  style={styles.buttonModal}
                >
                  <Text style={[styles.textSubjectModal, { color: "#A1A1A1" }]}>
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    callApi();
                  }}
                  style={[
                    styles.buttonModal,
                    { backgroundColor: "#161A31", marginLeft: 10 },
                  ]}
                >
                  <Text style={[styles.textSubjectModal, { color: "#FFFFFF" }]}>
                    Save
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>

        <Modal
          transparent={true}
          visible={modalDelete}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalDelete(!modalDelete);
          }}
        >
          <View style={styles.bgModal}>
            <View style={styles.viewDeleteModal}>
              <Text
                style={{ fontSize: 24, fontFamily: "Roboto", color: "#FFFFFF" }}
              >
                Delete
              </Text>
            </View>
            <View
              style={[
                styles.viewModal,
                {
                  width: "30%",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={{
                  fontSize: (size.width * 1.2) / 100,
                  fontFamily: "Roboto",
                  color: "#404040",
                }}
              >
                Do you want to delete data?
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignSelf: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setModalDelete(!modalDelete);
                  }}
                  style={styles.buttonModal}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#808080", fontSize: 16 },
                    ]}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={async () => {
                    console.log(Delete);

                    const response = await apiservice({
                      method: "Delete",
                      path: "api/admin_media/media/" + Delete.media_uid,
                      token: token.access_token,
                    });
                    console.log("Delete", response.data);
                    if (response.status == 200) {
                      mediaall();
                      setModalDelete(!modalDelete);
                    }
                  }}
                  style={[
                    styles.buttonModal,
                    { backgroundColor: "#161A31", marginLeft: 10 },
                  ]}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#FFFFFF", fontSize: 16 },
                    ]}
                  >
                    Ok
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </View>
  );
}
