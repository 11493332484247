import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { useRecoilState } from "recoil";
import { tokenState } from "../reducer/Atom";

export default function headerTitle({ title, navigation }) {
  const [token, settoken] = useRecoilState(tokenState);
  return (
    <View
      style={{
        height: 160,
        marginTop: 50,
        justifyContent: "center",
      }}
    >
      <Image
        style={{ width: "100%", height: 160, position: "absolute" }}
        source={require("../img/0006.png")}
      />
      <View
        style={{
          width: "95%",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          marginHorizontal: 50,
          marginBottom: 50,
        }}
      >
        <Text
          style={{
            fontSize: 48,
            fontFamily: "Roboto",
            color: "#FFFFFF",
            zIndex: 99,
            fontWeight: "bold",
          }}
        >
          {title}
        </Text>
        <TouchableOpacity
          onPress={() => {
            console.log(token);
            settoken({});
            setTimeout(() => {
              navigation.navigate("Login");
            }, 500);
          }}
          style={{
            width: 180,
            height: 50,
            backgroundColor: "#FFFFFF",
            borderRadius: 4,
            justifyContent: "center",
            alignItems: "center",
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
          }}
        >
          <Text
            style={{
              fontSize: 18,
              fontFamily: "Roboto",
              color: "#161A31",
              fontWeight: "bold",
            }}
          >
            Log out
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
